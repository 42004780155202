import { Venue } from '@citruscamps/citrus-client'

const calculateMatchScore = (placeName: string, venue: Venue): number =>
  Math.max(
    venue.place_name?.toLowerCase().includes(placeName.toLowerCase())
      ? placeName.length / venue.place_name.length
      : 0,
    venue.city?.toLowerCase().includes(placeName.toLowerCase())
      ? placeName.length / venue.city.length
      : 0,
    venue.region?.toLowerCase().includes(placeName.toLowerCase())
      ? placeName.length / venue.region.length
      : 0,
  )

export const findMatchVenue = (
  placeName: string | undefined,
  venues: Venue[],
): Venue | undefined => {
  if (!placeName) {
    return undefined
  }
  const result = venues.reduce<Venue | undefined>((bestVenue: Venue | undefined, venue: Venue) => {
    const score = calculateMatchScore(placeName, venue)
    if (!bestVenue && score > 0) {
      return venue
    }
    if (bestVenue) {
      const bestScore = calculateMatchScore(placeName, bestVenue)
      if (score > bestScore) {
        return venue
      }
    }
    return bestVenue
  }, undefined)
  return result
}
